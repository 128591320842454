/** React imports */
import React, { Context, createContext } from 'react';
/** Goldspot imports */
import {
  SignedInUser,
  AlertObject,
  ProjectObject,
  JobsResponse,
} from '@/typescript/interfaces';

export interface AppContextType {
  user: SignedInUser;
  company: string; // for switch companies
  setCompany: (value: string | '') => void;
  appAlerts: AlertObject[] | [];
  setAppAlerts: (value: AlertObject[] | []) => void;
  addAppAlert: (value: AlertObject) => void;
  appLoading: boolean;
  setAppLoading: (value: boolean) => void;
  activeProject: ProjectObject;
  setActiveProject: (value: ProjectObject | object) => void;
  refreshProjectObject: (projectId?: string) => Promise<void>;
  activeJob: JobsResponse;
  setActiveJob: (value: JobsResponse) => void;
  isDrawerOpen: boolean;
  setIsDrawerOpen: (value: boolean) => void;
}

/* eslint-disable @typescript-eslint/no-empty-function */
export const AppContext: Context<AppContextType> =
  createContext<AppContextType>({
    user: {} as SignedInUser,
    company: '', // for switch companies
    setCompany: (value: string) => {},
    appAlerts: [] as AlertObject[] | [],
    setAppAlerts: (value: any): void => {},
    addAppAlert: (value: AlertObject): void => {},
    appLoading: false,
    setAppLoading: (value: boolean): void => {},
    // The project is selected via URL
    activeProject: {},
    setActiveProject: (value: ProjectObject): void => {},
    refreshProjectObject: (projectId?: string): Promise<void> => {
      return new Promise((resolve, reject) => {
        resolve();
      });
    },
    activeJob: {
      company: '',
      description: '',
      id: '',
      project: '',
    },
    setActiveJob: (value: JobsResponse): void => {},
    isDrawerOpen: false,
    setIsDrawerOpen: (value: boolean): void => {},
  });
