/** @mui/material imports */
import Button from '@mui/material/Button/Button';
import Dialog from '@mui/material/Dialog/Dialog';
import DialogActions from '@mui/material/DialogActions/DialogActions';
import DialogContent from '@mui/material/DialogContent/DialogContent';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import Stack from '@mui/material/Stack/Stack';
/** @mui/icons-material imports */
/** React imports */
import { useTranslation } from 'react-i18next';
/** Goldspot imports */
import { AppAlertSeverity } from '@/typescript/types';

export interface DialogAlertDetailsProps {
  title?: string;
  details: string;
  severity: AppAlertSeverity;
  isDetailsDialogOpen: boolean;
  onClose: () => void;
  callToActionText?: string;
}

export const DialogAlertDetails: React.FC<DialogAlertDetailsProps> = (
  props: DialogAlertDetailsProps
): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Dialog
        maxWidth={'sm'}
        open={props.isDetailsDialogOpen}
        onClose={props.onClose}
        className={`dialog-alert-details ${props.severity}`}
      >
        <DialogTitle>
          {props.title || t('DIALOG_TITLE_ALERT_DETAILS')}
        </DialogTitle>
        <DialogContent sx={{ py: 0 }}>
          <Stack>
            <pre
              style={{
                overflow: 'auto',
                maxHeight: '8rem',
                margin: 0,
              }}
            >
              {props.details}
            </pre>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button variant="outlined" onClick={props.onClose}>
            {props.callToActionText || t('BUTTON_CANCEL')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
