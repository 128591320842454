import { AuthenticatedApiRequest } from 'gsf-util-react-auth';
import {
  ProjectObject,
  GetProjectsListParams,
  ProjectsList,
  ProjectRequestObject,
} from '@/typescript/interfaces';
import { ProjectObjectUpdate } from '@/typescript/types';

export const getProject = (
  company: string,
  projectId: string,
  expand?: boolean
): Promise<ProjectObject> => {
  return new Promise((resolve, reject) => {
    let path = '';
    if (expand) {
      path = `/${company}/${projectId}?expand=true`;
    } else {
      path = `/${company}/${projectId}`;
    }
    AuthenticatedApiRequest({
      method: 'get',
      apiName: 'Projects',
      path,
    })
      .then((result: any) => {
        const projectObject: ProjectObject = result;
        resolve(projectObject);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getProjectsList = ({
  company,
  queryParams,
}: GetProjectsListParams): Promise<ProjectsList> => {
  const params: string[] = Object.keys(queryParams || {}).map(
    (key: string): string => {
      const value: string = (queryParams || ({} as any))[key];
      if (typeof value === 'undefined' || value === null || value === '') {
        return '';
      } else {
        return `${key}=${value}`;
      }
    }
  );
  return new Promise((resolve, reject) => {
    AuthenticatedApiRequest({
      method: 'get',
      apiName: 'Projects',
      path: `/${company}?${params.join('&')}`,
    })
      .then((result: any) => {
        const projectsList: ProjectsList = result;
        resolve(projectsList);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createProject = (
  company: string,
  projectId: string,
  projectParams: ProjectRequestObject
): Promise<{ error?: string; message?: string }> => {
  return new Promise((resolve, reject) => {
    const path = `/${company}/${projectId}`;
    AuthenticatedApiRequest({
      method: 'put',
      apiName: 'Projects',
      path,
      postData: {
        body: projectParams,
      },
    })
      .then((result: any) => {
        const response: { error?: string; message?: string } = result;
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateProject = (
  company: string,
  projectId: string,
  updatedProperties: Partial<ProjectObjectUpdate>
): Promise<{ message: string }> => {
  return new Promise((resolve, reject) => {
    const path = `/${company}/${projectId}`;
    AuthenticatedApiRequest({
      method: 'post',
      apiName: 'Projects',
      path,
      postData: {
        body: updatedProperties,
      },
    })
      .then((result: any) => {
        if (result.error) {
          const response: { error: string; message: string } = result;
          reject(response);
        } else {
          const response: { message: string } = result;
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addUserToProject = (
  company: string,
  projectId: string,
  userId: string
): Promise<{ message: string }> => {
  return new Promise((resolve, reject) => {
    const path = `/${company}/${projectId}/users/${userId}`;
    AuthenticatedApiRequest({
      method: 'put',
      apiName: 'Projects',
      path,
    })
      .then((result: any) => {
        if (result.error) {
          const response: { error: string; message: string } = result;
          reject(response);
        } else {
          const response: { message: string } = result;
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const removeUserFromProject = (
  company: string,
  projectId: string,
  userId: string
): Promise<{ message: string }> => {
  return new Promise((resolve, reject) => {
    const path = `/${company}/${projectId}/users/${userId}`;
    AuthenticatedApiRequest({
      method: 'delete',
      apiName: 'Projects',
      path,
    })
      .then((result: any) => {
        if (result.error) {
          const response: { error: string; message: string } = result;
          reject(response);
        } else {
          const response: { message: string } = result;
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
